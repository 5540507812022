.app {
  margin: 0 auto;
  position: relative;
  display: flex;
  height: 100%;
  min-height: 100vh;
  padding: 0;
  flex-direction: column;
  align-items: center;
}

.content {
  flex: 1 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  height: auto;
}

img {
  user-select: none;
  -webkit-user-select: none;
}
