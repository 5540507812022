$body-font: Cormorant, sans-serif;

$title-font: PinyonScript;

$breakpoints: (
  "xs": 0px,
  "sm": 480px,
  "md": 768px,
  "lg": 992px,
  "xl": 1410px,
  "mlw": 940px,
  "mlh": 500px,
);

$pageWidth: 1410px;
$bg-footer: #eaeaf2;
$border-light: #eaeaf2;
$border: rgba(105, 106, 147, 0.1);
$text-primary: #2b2b2b;
$text-secondary: #696a93;
$text-link: #221649;
$ps-orange: #ff9939;
$ps-orange-dark: #dc822d;
$ps-orange-light: #ffebd8;
$bg-secondary: #f6f6fb;
$shadow: #bdbdbd;
$tanzanite: #b7418e;
$loading: #eaeaea;
$error: #d32f2f;
$red: #ef3131;
$green: #9cc23a;
$backgroundColor: #ffffff;
$backgroundColor100: rgba(154, 147, 145, 1); // 100% opacidad
$backgroundColor50: rgba(154, 147, 145, 0.5); // 50% opacidad
