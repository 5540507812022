@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";
.root {
  background-color: #da5b00;
  .page {
    @include page-base-no-padding;
  }
}

.title {
  font-family: $title-font !important;
}
