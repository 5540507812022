.widthInherit {
    width: inherit;
}

.autoWidth {
    width: auto;
    height: 100%;
}

.autoHeight {
    width: 100%;
    height: auto;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}