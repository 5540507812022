@import "../styles/variables";
@import "../styles/fonts.scss";
@import "../styles/mixins.scss";

html,
body {
  height: 100%;
  width: 100%;
  line-height: 1.5;
  touch-action: pan-y pinch-zoom;
  background-color: $backgroundColor;
}

body {
  min-height: 100%;
  font-family: $body-font;
  margin: 0;
}

body.fontLoaded {
  font-family: $body-font;
}

#app {
  height: 100%;
  min-width: 100%;
}

p,
label {
  font-family: $body-font;
  line-height: 1.5em;
}
