@import "../styles/variables";

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

@mixin respond-above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin respond-below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin respond-to($breakpoint, $orientation: null) {
  $size: map-get($breakpoints, $breakpoint);

  @if $size !=null {
    @if $orientation !=null {
      @media (min-width: $size) and (orientation: $orientation) {
        @content;
      }
    } @else {
      @media (min-width: $size) {
        @content;
      }
    }
  } @else {
    @warn "no value exists for `#{$breakpoint}` or `#{$orientation}`.";
  }
}

@mixin respond-to-mobile-screen($breakpoint, $orientation: null) {
  $size: map-get($breakpoints, $breakpoint);

  @if $breakpoint == "ml" {
    $mlwmin: map-get($breakpoints, "sm");
    $mlwmax: map-get($breakpoints, "mlw");
    $mlh: map-get($breakpoints, "mlh");

    @if $orientation !=null {
      @media (min-width: $mlwmin) and (max-width: $mlwmax) and (max-height: $mlh) and (orientation: $orientation) {
        @content;
      }
    } @else {
      @media (min-width: $mlwmin) and (max-width: $mlwmax) and (max-height: $mlh) {
        @content;
      }
    }
  } @else {
    @if $orientation !=null {
      @media (max-width: $size) and (orientation: $orientation) {
        @content;
      }
    } @else {
      @media (max-width: $size) {
        @content;
      }
    }
  }
}

@mixin flex-center-hv {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin double-pane-right {
  height: auto;
  min-height: 100vh;
  width: 100%;
  position: relative;
  margin: 0 auto;
  display: flex;
  padding: 0 0px;
  flex-direction: column;
  align-items: center;
  background-color: #fff;

  @media screen and (max-height: 580px) and (max-width: 450px) {
    height: calc(100% + 80px);
    min-height: 600px;
  }
}

@mixin double-pane-content {
  padding: 40px 0 0;
  flex: 1 0 auto;
  width: 100%;
  @include flex-center-hv;
  flex-direction: column;

  // to prevent host box from blocking the save button on password reset page on short browser
  @media screen and (max-height: 680px) {
    padding: 20px 0 0;
  }
}

@mixin login-bar-top {
  position: absolute;
  height: 10px;
  width: 100%;
  background-image: linear-gradient(
    to right,
    #ff6d42 0%,
    #ff9939 51%,
    #ffb88b 100%
  );

  @include respond-to("md") {
    display: none;
  }
}

@mixin loading {
  position: relative;
  background-color: $loading;
  opacity: 0.5;
  overflow: hidden;

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.5),
      transparent
    );
    animation: loading 1.25s infinite;
  }
}

@mixin bonus-box-anim-props($anim-duration) {
  animation-duration: $anim-duration;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: both;
}

@mixin animation($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-ms-keyframes #{$name} {
    @content;
  }

  @-o-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin absolute-center-hv() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin page-base-no-padding {
  width: 100%;
  margin: 0 auto;
  max-width: 1410px;

  @media only screen and (min-width: 1450px) {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin page-base {
  @include page-base-no-padding();
  padding-left: 20px;
  padding-right: 20px;
}

@mixin sticky {
  position: sticky;
  position: -webkit-sticky;
}
