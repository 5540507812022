@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.title {
  font-family: $title-font !important;
}

.center {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.fullscreen {
  width: 100vw;
  height: 100vh;
}

.fullsize {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}

.extended {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
}
