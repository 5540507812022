@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.pending {
    border-radius: 8px;
    @include loading;
    opacity: 1;
}

.block {
    display: block;
}

.hidden {
    display: none;
}

.pointer {
    cursor: pointer;
}

.noUserSelect {
    user-select: none;
}

.disabled {
    opacity: 0.5;
    user-select: none;
    cursor: default !important;
}

.relative {
    position: relative
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.fullWidth {
    width: 100%;
}

.italic {
    font-style: italic;
}

.alignLeft {
    text-align: left;
}

.alignCenter {
    text-align: center;
}

.alignRight {
    text-align: right;
}

.alignJustify {
    text-align: justify;
}

.textDecorationNone {
    text-decoration: none;

    a {
        text-decoration: none !important;
    }
}

.textTransformNone {
    text-transform: none;
}

.underline {
    text-decoration: underline;
}

.textTransformUpperCase {
    text-transform: uppercase;
}

.textTransformCapitalize {
    text-transform: capitalize;
}

.nowrap {
    white-space: nowrap;
}

.fontWeightInherit {
    font-weight: inherit;
}

.fontSizeInherit {
    font-size: inherit;
}

.lineHeightInherit {
    line-height: inherit;
}

.fontInherit {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
}

.colorTextLink {
    color: $text-link;
}

.colorPrimaryLink {
    color: $ps-orange;
}

.colorTextLink,
.colorPrimaryLink {
    * {
        color: inherit !important;
        text-decoration: inherit !important;
    }
}

.colorWhite {
    color: #fff;
}

.colorRed {
    color: $red;
}

.colorLightGray {
    color: #b0b0b0;
}

.colorError {
    color: $error;
}

.lineThrough {
    text-decoration: line-through;
}

// helpers
@mixin generateFontWeightClasses() {
    $fontWeights: (
        "light": 300,
        "regular": 400,
        "medium": 500,
        "bold": 600,
        "bolder": 700
    );

// generate font-weight classes 
@each $weight, $value in $fontWeights {
    .#{$weight} {
        font-weight: $value;
    }
}
}

@include generateFontWeightClasses();