@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.rootLogo {
  background-color: #fff;
  .page {
    @include page-base-no-padding;
  }
}
.square {
  border-radius: 15px;
  background-color: #da5b00;
  color: white;
}
.acceptButton {
  background: #da5b00;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.acceptButton:hover {
  background: #da5b00;
}

.acceptButton:active {
  background: #da5b00;
}
