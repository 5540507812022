@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.container {
  margin: 0 auto;
  max-width: $pageWidth;

  &Padding {
    padding-left: 20px;
    padding-right: 20px;

    @media only screen and (min-width: calc($pageWidth + 40px)) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
