@font-face {
  font-family: "Cormorant";
  font-style: normal;
  font-weight: 300;
  src: url("https://repo.agavesentreotros.com/fonts/CormorantGaramond-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Cormorant";
  font-style: normal;
  font-weight: 400;
  src: url("https://repo.agavesentreotros.com/fonts/CormorantGaramond-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Cormorant";
  font-style: normal;
  font-weight: 500;
  src: url("https://repo.agavesentreotros.com/fonts/CormorantGaramond-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Cormorant";
  font-style: normal;
  font-weight: 600;
  src: url("https://repo.agavesentreotros.com/fonts/CormorantGaramond-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Cormorant";
  font-style: normal;
  font-weight: 700;
  src: url("https://repo.agavesentreotros.com/fonts/CormorantGaramond-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Cormorant";
  font-style: normal;
  font-weight: 700;
  src: url("https://repo.agavesentreotros.com/fonts/CormorantGaramond-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "PinyonScript";
  font-style: normal;
  font-weight: 500;
  src: url("https://repo.agavesentreotros.com/fonts/PinyonScript-Regular.ttf")
    format("truetype");
}
