@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.rootLogo {
  background-color: #fff;
  .page {
    @include page-base-no-padding;
  }
}
.square {
  border-radius: 15px;
  background-color: #da5b00;
  color: white;
}
